import "@typeform/embed/build/css/popup.css"
import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { ApiProvider } from "@trueskin-web/apis"
import { AuthProvider } from "@trueskin-web/context"
import { GraphQLProvider } from "@trueskin-web/gql"
import { I18nProvider } from "@trueskin-web/translations"

// This has been manually build from https://github.com/lipis/flag-icons
// When a new flag is required it should be built locally and just flag-icons.min.css and svgs
import "./src/styles/flag-icons.min.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <GraphQLProvider>
    <ApiProvider>
      <I18nProvider>
        <AuthProvider>{element}</AuthProvider>
        <ToastContainer
          autoClose={3500}
          closeOnClick={true}
          position="top-center"
          hideProgressBar
        />
      </I18nProvider>
    </ApiProvider>
  </GraphQLProvider>
)
