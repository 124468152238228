/** @jsx jsx */
import { createContext, useContext, useMemo, useState } from "react"

import { jsx } from "@trueskin-web/theme"

const SubscriptionContext = createContext()

const SubscriptionProvider = (props) => {
  const [renewalDateDialog, setRenewalDateDialog] = useState(false)

  const value = useMemo(
    () => ({
      renewalDateDialog,
      setRenewalDateDialog,
    }),
    [renewalDateDialog]
  )

  return <SubscriptionContext.Provider value={value} {...props} />
}

const useSubscriptionContext = () => useContext(SubscriptionContext)

export { SubscriptionProvider, useSubscriptionContext }
