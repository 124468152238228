import { apiV1 } from "./api"

function getInitial() {
  return apiV1(`typeform/initial`)
}

function getCheckIn() {
  return apiV1(`typeform/checkin`)
}
function getQuestion() {
  return apiV1(`typeform/ask-your-doctor`)
}

function getRepurchase() {
  return apiV1(`typeform/repurchase`)
}

function submittedCheckIn({ responseId, imageUploadUid }) {
  return apiV1(`consultations/check-in/submitted`, {
    method: "POST",
    body: {
      responseId,
      imageUploadUid,
    },
  })
}

function submittedRepurchase({ responseId, imageUploadUid }) {
  return apiV1(`consultations/repurchase/submitted`, {
    method: "POST",
    body: {
      responseId,
      imageUploadUid,
    },
  })
}

function submittedMidTwoMonthsUpdate({ responseId, imageUploadUid }) {
  return apiV1("consultations/mid2mUpdate/submitted", {
    body: {
      responseId,
      imageUploadUid,
    },
  })
}

export {
  getInitial,
  getCheckIn,
  getQuestion,
  getRepurchase,
  submittedCheckIn,
  submittedRepurchase,
  submittedMidTwoMonthsUpdate,
}
