import { apiV1 } from "./api"

function getAll() {
  return apiV1("users/me/consultations")
}

function getById({ queryKey: [_, consultationId] }) {
  return apiV1(`users/me/consultations/${consultationId}`)
}

function getOpenedConsultations() {
  return apiV1("users/me/consultations/opened")
}

function getClosedCheckinConsultations() {
  return apiV1("users/me/consultations/check-ins")
}

function getClosedAydConsultations() {
  return apiV1("users/me/consultations/ayds")
}

function getConsultations({ queryKey: [_, category] }) {
  if (category.type === "opened") {
    return getOpenedConsultations()
  }

  if (category.type === "checkin") {
    return getClosedCheckinConsultations()
  }

  if (category.type === "askYourDoctor") {
    return getClosedAydConsultations()
  }

  return getAll()
}

function createAskYourDoctorConsultation({
  category,
  sideEffectsSeverity,
  text,
  skipImageUpload = true,
}) {
  return apiV1("users/me/consultations/ayd", {
    body: {
      category,
      text,
      sideEffectsSeverity,
      skipImageUpload,
    },
  })
}

function answerAskYourDoctorConsultation({
  consultationId,
  text,
  skipImageUpload = true,
}) {
  return apiV1(
    `users/me/consultations/ask-your-doctor/${consultationId}/answer`,
    {
      method: "PUT",
      body: {
        text,
        skipImageUpload,
      },
    }
  )
}

function rateAskYourDoctorConsultation({ consultationId, score }) {
  return apiV1(`users/me/consultations/${consultationId}/rate`, {
    body: { score },
  })
}

function getConsultationsFaqs() {
  return apiV1("ask-your-doctor-faqs")
}

function getInitialConsultation() {
  return apiV1("users/me/consultations/initial/answers")
}

export {
  getAll,
  getById,
  getConsultationsFaqs,
  getConsultations,
  createAskYourDoctorConsultation,
  answerAskYourDoctorConsultation,
  rateAskYourDoctorConsultation,
  getInitialConsultation,
}
