import { apiV1 } from "./api"

function getInitial({ id, provider }) {
  return apiV1("questionnaire/initial", {
    body: {
      id,
      provider,
    },
  })
}

function getMidTwoMonthsUpdate({ id, provider }) {
  return apiV1("questionnaire/mid2mUpdate", {
    body: {
      id,
      provider,
    },
  })
}

export { getInitial, getMidTwoMonthsUpdate }
