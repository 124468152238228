import i18next from "@trueskin-web/translations"

import { apiV1 } from "./api"
import * as marketingClient from "./marketing"

const getLocalizedError = (errorCode) => {
  if (i18next.exists(`Generic.errors.PostalCode.${errorCode}`)) {
    return i18next.t(`Generic.errors.PostalCode.${errorCode}`)
  }

  return i18next.t("Generic.errors.invalidPostalCode")
}

function createShippingAddress(values) {
  return apiV1("users/me/address", {
    body: values,
  }).catch((err) => {
    const postalCode = err?.data?.fields?.shippingAddress?.postalCode

    if (postalCode) {
      err.message = getLocalizedError(postalCode)
    }

    throw err
  })
}

function updateShippingAddress({ addressId, updates }) {
  return apiV1(`users/me/address/${addressId}`, {
    method: "PUT",
    body: updates,
  }).catch((err) => {
    const postalCode = err?.data?.fields?.shippingAddress?.postalCode

    if (postalCode) {
      err.message = getLocalizedError(postalCode)
    }

    throw err
  })
}

function deleteShippingAddress(addressId) {
  return apiV1(`users/me/address/${addressId}`, {
    method: "DELETE",
  })
}

function selectShippingAddress(addressId) {
  return apiV1(`users/me/address/${addressId}/select`, {
    method: "POST",
  })
}

function createBillingAddress(values) {
  return apiV1("users/me/billing-address", {
    body: values,
  }).catch((err) => {
    const postalCode = err?.data?.fields?.billingAddress?.postalCode

    if (postalCode) {
      err.message = getLocalizedError(postalCode)
    }

    throw err
  })
}

function updateBillingAddress({ billingAddressId, updates }) {
  return apiV1(`users/me/billing-address/${billingAddressId}`, {
    method: "PUT",
    body: updates,
  }).catch((err) => {
    const postalCode = err?.data?.fields?.billingAddress?.postalCode

    if (postalCode) {
      err.message = getLocalizedError(postalCode)
    }

    throw err
  })
}

function deleteBillingAddress(billingAddressId) {
  return apiV1(`users/me/billing-address/${billingAddressId}`, {
    method: "DELETE",
  })
}

function selectBillingAddress(billingAddressId) {
  return apiV1(`users/me/billing-address/${billingAddressId}/select`, {
    method: "POST",
  })
}

function createPharmacy(values) {
  return apiV1("users/me/pharmacy", {
    body: values,
  })
}

function updatePharmacy({ pharmacyId, updates }) {
  return apiV1(`users/me/pharmacy/${pharmacyId}`, {
    method: "PUT",
    body: updates,
  })
}

function deletePharmacy(pharmacyId) {
  return apiV1(`users/me/pharmacy/${pharmacyId}`, {
    method: "DELETE",
  })
}

function selectPharmacyAsShippingAddress(pharmacyId) {
  return apiV1(`users/me/pharmacy/${pharmacyId}/select`, {
    method: "POST",
  })
}

function saveAddresses(values) {
  marketingClient.sendEvent({
    name: marketingClient.EVENT_TYPES_ENUM.SHIPPING_SUBMITTED,
  })

  return apiV1("users/me/addresses", {
    body: values,
  })
}

export {
  createShippingAddress,
  updateShippingAddress,
  deleteShippingAddress,
  selectShippingAddress,
  createBillingAddress,
  updateBillingAddress,
  deleteBillingAddress,
  selectBillingAddress,
  createPharmacy,
  updatePharmacy,
  deletePharmacy,
  selectPharmacyAsShippingAddress,
  saveAddresses,
}
