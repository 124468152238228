import { apiV1 } from "./api"

function getNpsSettings() {
  return apiV1("nps")
}

function submitScore({ npsScoreId, score, source }) {
  return apiV1(`score/${npsScoreId}`, {
    body: {
      score,
      source,
    },
  })
}

function trackReview({ npsScoreId, platform }) {
  return apiV1(`score/${npsScoreId}/review`, {
    body: {
      platform,
    },
  })
}

function submitFeedback({ npsScoreId, feedback, tags }) {
  return apiV1(`score/${npsScoreId}/feedback`, {
    body: {
      feedback,
      tags,
    },
  })
}

export { getNpsSettings, submitScore, trackReview, submitFeedback }
