import { apiV1 } from "./api"

function getSiteConfig() {
  return apiV1("platform-settings/site-config")
}

function getBannerConfig() {
  return apiV1("platform-settings/banner-config")
}

export { getSiteConfig, getBannerConfig }
