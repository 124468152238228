import { formatDateISO } from "./formatters"

export const getDaysToCurrentDate = (dateString) => {
  const today = new Date()
  const date = new Date(dateString)

  return date.getTime() - today.getTime()
}

export const getDaysInMs = (days = 30) => days * 24 * 60 * 60 * 1000

export const getDaysBetweenDates = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0
  }

  return (endDate - startDate) / 1000 / 60 / 60 / 24
}

export const addDaysToCurrentDate = (days, formatToISO = false) => {
  return addDaysToDate(days, new Date(), formatToISO)
}

export const addDaysToDate = (days, dateString, formatToISO = false) => {
  const date = dateString ? new Date(dateString) : new Date()
  date.setDate(date.getDate() + days)
  return formatToISO ? formatDateISO(date) : date
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}
